import React from "react";
import s from './NavBar.module.scss';
import { navigate } from '@reach/router';
import agilisLogo from '../images/Logo-Agilis-blanco.svg';
import agilisBlue from '../images/icons/Logo_azul_grande.svg';
import BurgerMenu from './BurgerMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import { Link } from "gatsby";
import Links from './Links';
import arrow_up from '../images/icons/up-arrrow-white-01.svg';
import arrow_down from '../images/icons/Dropdown arrow white.svg';
import up_arrow_blue from '../images/icons/up-arrow-blue.svg';
import down_arrow_blue from '../images/icons/down-arrow-blue.svg';
import { TOP_LEVEL_PREFIX } from '../config.js';


export default class NavBar extends React.Component {

    state = {
      open: false,
      showMenu: false,
    }

    componentDidMount = () => {
       document.addEventListener('click', this.handleClick, false)
    }

    componentWillUnmount = () => {
        document.removeEventListener('click', this.handleClick, false)
    }

    handleClick = (e) =>{
        if(!this.node.contains(e.target)){
            this.setState({
                showMenu:false
            });
        }
    }

    toggleOpen = () => {
      this.setState(prevState => {
        if (!prevState.open) {
          document.body.classList.add(s.lock);
          document.querySelector('html').classList.add(s.lock)
        }
        else {
          document.body.classList.remove(s.lock);
          document.querySelector('html').classList.remove(s.lock)
        }
        return {
          open: !prevState.open,
        }
      });
    }

    toggleMenuCredits = (e) => {
        e.preventDefault();
        this.setState({ showMenu: !this.state.showMenu });
    }

  render(){
      const { user, userData, blue, productType } = this.props;
      const { showMenu } = this.state;
      return(
        <div className={s.navBarContainer}>
             <Link to="/"><img className={!blue ? '' : s.logoBlue} src={!blue ? agilisLogo : agilisBlue } alt='Logo Agilis'/></Link>
            <ul className={`${s.list} ${blue && s.listBlue}`} ref={node => this.node = node}>
              <Links title='Nosotros' href='/nosotros'/>
              <Links title='Nuestros Créditos' onClick={this.toggleMenuCredits} src={(showMenu) ? (blue) ? up_arrow_blue : arrow_up : (blue) ? down_arrow_blue : arrow_down} className={s.whiteArrow}/>
              {showMenu &&
                  <div className={s.creditMenu}>
                      <Links title='Libre destino con Garantía Vehícular' href='/car'/>
                      <Links title='Libre destino con Garantía Hipotecaria' href='/home'/>
                  </div>
              }
              <Links title='Preguntas Frecuentes' href='/faqs'/>
              <Links title='Revista' target='__blank' href='https://blog.agilis.com.ar/'/>
              <Links title='Contacto' onClick={(productType !== 'Nosotros') ? () => navigate('#contacto') : () => navigate('/#contacto')}/>
              {user
                ? <ProfileMenu userData={userData && userData}/>
                : (productType !== 'CarPur') 
                    ? <div>
                        <i className={!blue ? s.userText : s.userTextBlue}>¿Ya sos usuario?</i>
                        <li><a href={`${TOP_LEVEL_PREFIX}/app/auth/login?next=${TOP_LEVEL_PREFIX}/app/hd/completa-tus-datos/mi-prestamo`}> <strong>Ingresá a tu cuenta</strong></a>
                        </li>
                    </div>
                    : <li><a href={`${TOP_LEVEL_PREFIX}/app/auth/login?next=${TOP_LEVEL_PREFIX}/app/hd/completa-tus-datos/mi-prestamo`}> <strong>Iniciar nueva solicitud</strong></a>
                    </li>
              }
          </ul>
          <div className={s.burgerMenu}>
            <BurgerMenu open={this.state.open} toggleOpen={this.toggleOpen} blue={blue}>
              {() => (
                  <MobileMenu user={user} userData={userData && userData} productType={productType}/>
              )}
          </BurgerMenu>
          </div>

        </div>
      )
}
}
